<!-- Localized -->
<template>
<div>
    <div class="shadow-md border-gray-100 bg-white rounded-md p-4 relative">
        <div class="document_icon min-w-full relative" style="height:320px">
            <galleryView :images="document.docs" />
            <div class="action_icons absolute top-4 right-4 flex space-x-4">
                <font-awesome-icon v-tippy :content="$t('integrated_checks.preview_documents')" icon="eye" @click="handleRevealDocuemnt" class="text-gray-400 hover:text-primary text-lg cursor-pointer outline-none" />
                <font-awesome-icon icon="download" @click="downloadDocuments" v-tippy :content="$t('integrated_checks.download_documents')" class="text-gray-400 hover:text-primary text-lg cursor-pointer outline-none" />
            </div>
        </div>
        <div class="document_info flex justify-center items-center gap-3 mt-2 text-gray-700 font-semibold capitalize">
            {{document.doc_type || ""}}
            <!-- <button @click="handleRevealDocuemnt" class="bg-primary text-white-text px-3 py-2 rounded-full">
                Preview Document
            </button> -->
            <!-- <button class="border border-primary text-primary py-2 px-3 rounded-full">
                Reject Document
            </button> -->
        </div>
        <!-- <div class="absolute -top-4 flex items-center gap-1 bg-primary rounded-full text-white-text px-2 font-bold text-sm">
            <span class="bg-primary text-white-text h-6 w-6 rounded-full text-md flex items-center justify-center ">
                <font-awesome-icon icon="check" />
            </span>
            Verified
        </div> -->
    </div>
    <div v-if="revealDocument" class="fixed top-0 right-0 left-0 bottom-0  z-50 h-screen" style="background-color: rgba(0, 0, 0, 0.4)">
        <div class="flex flex-1 min-h-full min-w-full items-center justify-center relative flex-col " style="min-height:100%; height:50%">
            <div class="w-8/12 min-h-full p-10" style="min-height: 50%;" >
                <galleryView  :images="document.docs" />  
            </div>
            <span @click="handleClosePreview"><font-awesome-icon  icon="times" class="text-3xl text-white-text absolute top-5 right-5 cursor-pointer" /></span>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>

<script>
import galleryView from './galleryView.vue'

export default {
    name:"document-preview",
    components:{
        galleryView,
    },
    props: ["document"],
    data(){
        return{
            revealDocument: false,
        }
    },
    methods:{
        handleRevealDocuemnt(){
            this.revealDocument = true
        },
        handleClosePreview(){
            this.revealDocument = false
        },
        downloadDocuments(){
            function download(uri, name) {
                var link = document.createElement("a");
                link.download = name;
                link.href = uri;
                link.target = '_blank'
                link.click();
            }
            var counter = 0;
            for (let i = 0; i <= this.document.docs.length -1 ; i++) 
            { 
                download(this.document.docs[i].link, this.document.docs[i].name+ counter);
                counter++;
            }
        }
    }
}
</script>
