<!-- Localized -->
<template>

<div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 justify-between">
    <div class="bg-white shaodw gap-2 rounded-md flex flex-col" v-for="score in scoreLists" :key="score.name">
		<h2 class="bg-gray-200 text-center px-3 py-2 flex-1 font-semibold text-gray-600 capitalize">{{score.name}}</h2>
		<div class="flex justify-center items-center p-4">
            <RadialProgress :value="score.value" :total=100 color="success" :size=120 />
		</div>
    </div>
</div>
    
</template>

<script>
import RadialProgress from "../../radial-progress"
export default {
    name:"scores",
    props:{
        scores:{
            type: Object,
            default: () => {}
        },
    },
	components:{
		RadialProgress,
	},
    computed:{
        scoreLists(){
            const scoreKeys=Object.keys(this.scores);
            let newScore = scoreKeys.map(el => ({name: el, value: this.scores[el]}))  
            return newScore
        }
    }
}
</script>

<style scoped>

</style>
