<!-- Localized -->
<template>
  <div class="h-full flex items-center w-full  xl:w-1/3"> 
    <div class="h-full layout rounded-lg overflow-hidden text-center">
        <Loader height="16px" width="16px" :loading="isLoading" v-if="isLoading" />

        <template v-else>
            <div class="flex flex-col gap-4">
                <div class="bg-custom-gray border rounded-lg">
                    <div class="flex items-center h-10 bg-blue-600 rounded-t-lg text-white-text px-4 font-bold">
                        {{ $t('integrated_checks.dbs_outputs') }}
                    </div>

                    <div class="p-4 overflow-y-auto scroll-bar flex flex-col gap-2" style="height: calc(100% - 40px)">
                        <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                            <span class="font-semibold px-2 w-3/5">{{ $t('integrated_checks.application_status') }}</span>
                            <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5 uppercase"> 
                                <span> {{ dbsStatus.data?.ApplicationStatus }} </span>
                            </span>
                        </div>
                        <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                            <span class="font-semibold capitalize px-2 w-3/5">{{ $t('integrated_checks.dbs_application_form_reference') }}</span>
                            <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                                <span> {{ dbsStatus.data?.DBSApplicationFormReference }} </span>
                            </span>
                        </div>
                        <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                            <span class="font-semibold capitalize px-2 w-3/5">{{ $t('integrated_checks.reg_org_application_reference') }}</span>
                            <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                                <span> {{ dbsStatus.data?.RegOrgApplicationReference}} </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <button
                        @click="handleDBSModal"
                        :disabled="!dbsStatus || !dbsStatus.data || !dbsStatus.data.RegOrgApplicationReference"
                        :class="
                            !dbsStatus || !dbsStatus.data || !dbsStatus.data.RegOrgApplicationReference
                            ? 'cursor-auto bg-gray-200 border-gray-200 text-gray-400'
                            : 'border-primary text-primary '
                        "
                        class="border py-2 px-3 rounded-lg ml-auto"
                    >
                        {{ $t('integrated_checks.view_report') }}
                    </button>
                </div>
            </div>
        </template>
    </div>


    <modal name="dbs-report" width="45%" height="50%">
      <div class="header bg-primary py-4 px-8 text-white-text text-lg font-bold flex items-center justify-between">
        <span>{{ $t('integrated_checks.report') }}</span>
        <font-awesome-icon
          icon="times"
          class="cursor-pointer"
          @click="$modal.hide('dbs-report')"
        />
      </div>
      <div class="w-full h-full flex items-center justify-center bg-white flex-1" v-if="isLoading">
        <Loader
          height="16px"
          width="16px"
          :loading="isLoading"
        />
      </div>
      <div class="w-full bg-white flex-1 justify-center flex overflow-y-auto scroll-bar" style="height: calc(100% - 60px)" v-else>
        <div class="bg-white py-4 px-16 w-full h-full">
            <div class="font-semibold border-b border-gray-300 py-2">
                <div class="flex gap-2 items-center text-lg">
                    <span>{{ $t('integrated_checks.dbs_report') }}</span> 
                    <font-awesome-icon icon="sync" class="cursor-pointer hover:animate-spin outline-none ml-auto" 
                        :class="{ 'animate-spin': isLoading }" v-tippy :content="$t('integrated_checks.refresh_dbs_status')" 
                        @click="getDbsReport(true)"> 
                    </font-awesome-icon>
                </div>
            </div>

            <div class="border-b border-gray-300 py-2">
                <div class="flex gap-1">
                    <div class="w-1/2">{{ $t('integrated_checks.status') }}</div>
                    <div class="capitalize font-bold">
                        {{ dbsData?.status || '-' }}
                    </div>
                </div>

                <div class="flex gap-1">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.application_status') }}</div>
                    <div class="capitalize font-bold">
                        {{ dbsData?.status_data.application_status || '-' }}
                    </div>
                </div>

                <div class="flex gap-1">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.assemble_certificate_date') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.status_data.assemble_certificate_date || '-' }}
                    </div>
                </div>

                <div class="flex gap-1">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.certificate_dispatched_date') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.status_data.certificate_despatched_date || '-' }}
                    </div>
                </div>

                <div class="flex gap-1">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.police_national_computer_search_date') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.status_data.police_national_computer_search_date || '-' }}
                    </div>
                </div>

                <div class="flex gap-1">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.received_date') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.status_data.received_data || '-' }}
                    </div>
                </div>

                <div class="py-4"></div>

                <div class="flex gap-1" v-if="dbsData?.report">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.disclosure_number') }}</div>
                    <div class="capitalize font-bold">
                        {{ dbsData?.report.DisclosureNumber || '-' }}
                    </div>
                </div>

                <div class="flex gap-1" v-if="dbsData?.report">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.disclosure_status') }}</div>
                    <div class="capitalize font-bold">
                        {{ dbsData?.report.DisclosureStatus || '-' }}
                    </div>
                </div>

                <div class="flex gap-1" v-if="dbsData?.report">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.disclosure_type') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.report.DisclosureType || '-' }}
                    </div>
                </div>

                <div class="flex gap-1" v-if="dbsData?.report">
                    <div class="w-1/2 capitalize">{{ $t('integrated_checks.disclosure_issue_date') }}</div>
                    <div class="capitalize">
                        {{ dbsData?.report.DisclosureIssueDate || '-' }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <Empty text="Report not found" v-else /> -->
      </div>
    </modal>
  </div>
</template>


<script>
import Loader from "../../loader/";
import axios from "@/axios";

export default {
    name: "DbsCheckResultsViewer",
    components: {
        Loader,
    },
    props: {
        caseId: String,
        status: Object,
        loading: Boolean,
    },
    data() {
        return {
            dbsData: null,
            dbsStatus: null,
            isLoading: false,
        };
    },
    watch: {
        status: function(newVal) {
            this.dbsStatus = newVal;
        },
        loading: function(newVal) {
            this.isLoading = newVal;
        }
    },
    async created() {
        this.dbsStatus = this.status;
        this.isLoading = this.loading;
    },
    methods: {
		handleDBSModal() {
			this.getDbsReport();
			this.$modal.show("dbs-report");
		},
		runDBS(refresh) {
			if (this.caseId) this.getCheckResults(refresh);
		},
		async getCheckResults(refresh) {
			this.isLoading = true;
			let refresh_flag = refresh ? refresh: false;
			try {
				let url = `/integrations/dbs/${this.caseId}?refresh=${refresh_flag}`;
				let { data } = await axios.get(url);
				// to do : remove this dummy link
				this.dbsStatus = data;
			} catch (error) {
				this.$toast.error(
				error.response.data.detail ||
					this.$t('integrated_cheks.smth_wrong_try_again')
				);
			}
			this.isLoading = false;
		},
		async getDbsReport(refresh) {
			// this.isLoading = true;
            let refresh_flag = refresh ? refresh: false;
			try {
				let url = `/integrations/dbs/${this.caseId}?refresh=${refresh_flag}`;
				let { data } = await axios.get(url);
				// to do : remove this dummy link
				this.dbsData = data;
			} catch (error) {
				this.$toast.error(
					error.response.data.detail ||
					this.$t('integrated_cheks.smth_wrong_try_again')
				);
			}
			// this.isLoading = false;
		},
    },
};
</script>

<style scoped>
.bg-custom-gray {
  background-color: #f2f2f2;
}
</style>

